/*
 * Keyframes
 */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
/*
 * CSS Page Transitions
 * Don't forget to add vendor prefixes!
 */

/** Basic styles for an animated element */
.scene_element {
  animation-duration: 0.1s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
}

/** An element that fades in */
.scene_element--fadein {
  animation-name: fadeIn;
}

/** An element that fades in and slides up */
.scene_element--fadeinup {
  animation-name: fadeInUp;
}

/** An element that fades in and slides from the right */
.scene_element--fadeinright {
  animation-name: fadeInRight;
}
