html {
  align-items: center;
  background-color: #111111;
  justify-content: center;
}

#inner-triangle-1 {
  opacity: 0;
  animation: tri-1 200ms infinite;
}

#inner-triangle-2 {
  opacity: 0;
  animation: tri-2 200ms infinite;
}

#inner-triangle-3 {
  opacity: 0;
  animation: tri-3 200ms infinite;
}

#inner-triangle-4 {
  opacity: 0;
  animation: tri-4 200ms infinite;
}

#inner-triangle-5 {
  opacity: 0;
  animation: tri-5 200ms infinite;
}

#inner-triangle-6 {
  opacity: 0;
  animation: tri-6 200ms infinite;
}

#caret-wrapper {
  animation: caret-moving 600ms infinite;
}

#caret {
  fill: white;
  animation: caret 600ms infinite;
}

@keyframes tri-1 {
  0% {
    opacity: 0;
  }
  16.67% {
    opacity: 1;
  }
}
@keyframes tri-2 {
  16.67% {
    opacity: 0;
  }
  33.33% {
    opacity: 1;
  }
}
@keyframes tri-3 {
  33.33% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes tri-4 {
  50% {
    opacity: 0;
  }
  66.67% {
    opacity: 1;
  }
}
@keyframes tri-5 {
  66.67% {
    opacity: 0;
  }

  83.33% {
    opacity: 1;
  }
}
@keyframes tri-6 {
  83.33% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes caret {
  from {
    fill: white;
    opacity: 1;
  }
  to {
    fill: #ccc;
    opacity: 0.1;
  }
}
@keyframes caret-moving {
  to {
    transform: translateY(5px);
  }
}
